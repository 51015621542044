import Vue from 'vue'
import VueRouter from 'vue-router'
// 报错解决
// 获取原型对象push函数
const originalPush = VueRouter.prototype.push

// 获取原型对象replace函数
const originalReplace = VueRouter.prototype.replace

// 修改原型对象中的push函数
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

// 修改原型对象中的replace函数
VueRouter.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch(err => err)
}


Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: 'index',
    component: ()=>import("../views/index.vue")
  },
  // {
  //   path: '/jituangaikuo',
  //   name: 'jituangaikuo',
  //   component: ()=>import("../views/jituangaikuo.vue")
  // },
  {
    path: '/C5',
    name: 'C5',
    component: ()=>import("../views/C5.vue")
  },
  {
    path: '/C7',
    name: 'C7',
    component: ()=>import("../views/C7.vue")
  },
    {
    path: '/Bike',
    name: 'bike',
    component: ()=>import("../views/Bike.vue")
  },
      {
    path: '/Iot',
    name: 'iot',
    component: ()=>import("../views/Iot.vue")
  },
  {
    path: '/About',
    name: 'about',
    component:()=>import("../views/About.vue")
  },
    {
    path: '/product',
    name: 'product',
    component: ()=>import("../views/product.vue"),
  },
  {
    path: '/productDetail/:id',
    name: 'productDetail',
    component: ()=>import("../views/productDetail.vue"),
  },
  {
    path: '/news',
    name: 'news',
    component: ()=>import("../views/news.vue"),
  },
  {
    path: '/newsxiangqing/:id',
    name: 'newsDetal',
    component: ()=>import("../views/newsxiangqing.vue"),
  },
  {
    path: '/lianxi',
    name: 'lianxi',
    component:()=>import("../views/lianxiwomen.vue")
  },
    {
    path: '/newsxiangqing0',
    name: 'newsDetal',
    component: ()=>import("../views/newsxiangqing.vue")
  }, {
    path: '/newsxiangqing1',
    name: 'newsDetal1',
    component: ()=>import("../views/NewsDetals/newsxiangqing1.vue")
  },
  {
    path: '/newsxiangqing2',
    name: 'newsDetal2',
    component:()=>import("../views/NewsDetals/newsxiangqing2.vue")
  },
  // {
  //   path: '/zhaopinxiangqing0_0',
  //   name: 'zhaopinxiangqing0_0',
  //   component: ()=>import("../views/Recruitment-details/zhaopinxiangqing0-0.vue"),
  // },
  // {
  //   path: '/zhaopinxiangqing0_1',
  //   name: 'zhaopinxiangqing0_1',
  //   component: ()=>import("../views/Recruitment-details/zhaopinxiangqing0-1.vue")
  // }, {
  //   path: '/zhaopinxiangqing1_0',
  //   name: 'zhaopinxiangqing1_0',
  //   component: ()=>import("../views/Recruitment-details/zhaopinxiangqing1-0.vue")
  // }, {
  //   path: '/zhaopinxiangqing1_1',
  //   name: 'zhaopinxiangqing1_1',
  //   component:()=>import("../views/Recruitment-details/zhaopinxiangqing1-1.vue")
  // }
]

const router = new VueRouter({
  routes,
  // 跳转页面后 回到顶部
  scrollBehavior() {
    return {
      x: 0,
      y: 0
    }
  }
})

export default router